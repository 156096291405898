
/*
  HTML Main component

  creates the structure for the application Body
*/
// Libs
import Vue from 'vue'

/*
  Vuejs Interfaces
*/
interface IProps {
  noServers: boolean
  noServices: boolean
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IData {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IComputed {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IMethods {}

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  name: 'EmptyConfig',

  props: {
    noServers: {
      type: Boolean,
      required: true,
    },
    noServices: {
      type: Boolean,
      required: true,
    },
  },
})
