
/*
  Select Service Fields Dialog

  requires a Service Watcher and an example JSON record
  Listens to Feathers Service for dialog requests

  returns a record, property names maps to types
  {
    text: "number",
    _id: "string"
  }
*/
// Libs
import Vue from 'vue'
import { Deferred } from 'ts-deferred'

// Constants / Interfaces
import { DataRecord } from '@/services/feathers-server.interfaces'
import { SelectServiceFieldsDialogProps } from '@/controllers/dialog.interfaces'
import { ShowDialogProps } from '@/controllers/app-ctrl.interfaces'
import {
  IService,
  PropertyLookupHash,
  ServiceField,
} from '@/models/service.interfaces'
import { Events, PropertyTypes } from '@/controllers/dialog.constants'

// Utils
import { sanitizeRecordFields } from '@/utils/data-utils'

interface PropertyTypeItem {
  label: string
  value: string
}

/*
  Vuejs Interfaces
*/
interface IProps {
  data: ShowDialogProps
}

interface IData {
  dialogForm: DataRecord
  propertyTypes: PropertyTypeItem[]
}

interface IComputed {
  props: SelectServiceFieldsDialogProps
  deferred: Deferred<PropertyLookupHash>
  recordTmpl: DataRecord
  serviceInstance: IService
  cleanedServiceFields: DataRecord
  recordKeys: string[]
  serviceFields: ServiceField[]
}

interface IMethods {
  _handleCancelDialog(): void
  _handleConfirmDialog(): void
  _updateDialogForm(): void
}

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  props: {
    data: {
      type: Object as () => ShowDialogProps,
      required: true,
    },
  },

  data() {
    return {
      dialogForm: {}, // generated by watchers
      propertyTypes: PropertyTypes,
    }
  },

  computed: {
    props() {
      const { data } = this
      return data.props as SelectServiceFieldsDialogProps
    },

    deferred() {
      const { data } = this
      return data.deferred
    },

    recordTmpl(): DataRecord {
      const { props } = this
      return props.recordTmpl
    },

    serviceInstance() {
      const { props } = this
      return props.serviceInstance
    },

    recordKeys() {
      return Object.keys(this.dialogForm)
    },

    serviceFields() {
      const { serviceInstance } = this
      return serviceInstance.fields
    },

    /*
      get fields from Selected Service
      unpack structure into key/value format
    */
    cleanedServiceFields() {
      const { serviceFields } = this

      const cleanFields: DataRecord = {}
      serviceFields.forEach(function (field) {
        const { property, type } = field
        cleanFields[property] = type || ''
      })
      return cleanFields
    },
  },

  methods: {
    _handleCancelDialog() {
      this.deferred.reject(Events.CANCELLED)
    },

    /*
      take results of Dialog Form and filter out ""
    */
    _handleConfirmDialog() {
      const { deferred, dialogForm } = this

      const newFields: PropertyLookupHash = {}
      for (let prop in dialogForm) {
        const value = dialogForm[prop]
        if (value !== '') {
          newFields[prop] = value
        }
      }
      deferred.resolve(newFields)
    },

    /*
      create dynamic structure for the Dialog Form
      merge two JSON structures
    */
    _updateDialogForm() {
      const { recordTmpl, cleanedServiceFields } = this
      const sanitizedFields = sanitizeRecordFields(recordTmpl)
      this.dialogForm = Object.assign(sanitizedFields, cleanedServiceFields)
    },
  },

  watch: {
    dataRecord() {
      this._updateDialogForm()
    },
  },

  created() {
    this._updateDialogForm()
  },
})
