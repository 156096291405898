
/*
  Server Selection component
  displays configured servers, allows selection of current server
*/

// Libs
import Vue from 'vue'

// Constants & Interfaces
import { IService } from '@/models/service.interfaces'
import { AddServiceDialogProps } from '@/controllers/dialog.interfaces'

// Controllers & Services
import AppCtrl from '@/controllers/app-ctrl.model'

interface ServiceDisplay {
  id: string
  path: string
  isError: string | null
}

const serviceDisplaySort = function (a: ServiceDisplay, b: ServiceDisplay) {
  const aPath = a.path.toLowerCase()
  const bPath = b.path.toLowerCase()
  if (aPath < bPath) {
    return -1
  }
  if (aPath > bPath) {
    return 1
  }
  return 0
}

/*
  Vuejs Interfaces
*/
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IData {}

interface IComputed {
  serviceInstanceList: IService[]
  haveServices: boolean
  serviceDisplayItems: ServiceDisplay[]
  servicePaths: string[]
}

interface IMethods {
  _handleAddService(): void
  _handleRemoveService(service: ServiceDisplay): void
  _handleSelectService(service: ServiceDisplay): void
}

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  name: 'ServerSelector',

  computed: {
    serviceInstanceList() {
      return AppCtrl.serviceInstanceList
    },

    haveServices() {
      const { serviceInstanceList } = this
      return serviceInstanceList.length > 0
    },

    serviceDisplayItems() {
      const { serviceInstanceList } = this

      return serviceInstanceList
        .map((service: IService) => {
          const { isError, path, id } = service
          return {
            id,
            path,
            isError,
          }
        })
        .sort(serviceDisplaySort)
    },

    servicePaths() {
      const { serviceInstanceList } = this
      return serviceInstanceList.map((service: IService) => service.path)
    },
  },

  methods: {
    _handleAddService() {
      const { servicePaths } = this
      const props: AddServiceDialogProps = {
        current: null,
        existing: servicePaths,
      }
      void AppCtrl.showAddServiceDialog(props)
    },

    _handleRemoveService(service: ServiceDisplay) {
      AppCtrl.removeService(service.id)
    },

    _handleSelectService(service: ServiceDisplay) {
      AppCtrl.setCurrentServiceId(service.id)
    },
  },
})
