
/*
  Server Selection component
  displays configured servers, allows selection of current server
*/

// Libs
import Vue from 'vue'

// Constants & Interfaces
import { Server } from '@/models/server.interfaces'

// Controllers & Services
import AppCtrl from '@/controllers/app-ctrl.model'

interface SelectOption {
  value: string
  label: string
}

/*
  Vuejs Interfaces
*/
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {}

interface IData {
  selectedServerId: string
}

interface IComputed {
  currentServerId: string | null
  serversList: Server[]
  selectOptions: SelectOption[]
  selectorPlaceholderString: string
}

interface IMethods {
  _handleSelectServer(serverId: string): void
  _handleClickServerManagement(): void
}

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  name: 'ServerSelector',

  data() {
    return {
      selectedServerId: '',
    }
  },

  computed: {
    currentServerId() {
      return AppCtrl.currentServerId
    },

    serversList() {
      return AppCtrl.serversList
    },

    selectOptions() {
      const { serversList } = this

      return serversList.map(function (item) {
        const { id, url, name } = item

        const label = name !== '' ? `${name} (${url})` : url
        return {
          value: id,
          label,
        }
      })
    },

    selectorPlaceholderString() {
      const { serversList } = this
      return serversList.length == 0 ? 'No Servers' : 'Select Server'
    },
  },

  methods: {
    _handleSelectServer(serverId: string) {
      AppCtrl.activateServerById(serverId)
    },

    _handleClickServerManagement() {
      void AppCtrl.showManageServersDialog()
    },
  },

  watch: {
    currentServerId(nV) {
      this.selectedServerId = nV || ''
    },
  },

  mounted() {
    const { currentServerId } = this
    this.selectedServerId = currentServerId || ''
  },
})
