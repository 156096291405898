
// Libs
import Vue from 'vue'
import { Deferred } from 'ts-deferred'

// Constants / Interfaces
import { ShowDialogProps } from '@/controllers/app-ctrl.interfaces'
import { Server } from '@/models/server.interfaces'

// Controllers & Services
import AppCtrl from '@/controllers/app-ctrl.model'

/*
  Vuejs Interfaces
*/
export interface IProps {
  data: ShowDialogProps
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IData {}

export interface IComputed {
  haveServers: boolean
  serversList: Server[]
  deferred: Deferred<void>
}

export interface IMethods {
  _handleAddNewServer(): void
  _handleConfirmDialog(): void
  _handleEditServer(server: Server): void
  _handleRemoveServer(server: Server): void
}

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  name: 'ManageServerDialog',

  props: {
    data: {
      type: Object as () => ShowDialogProps,
      required: true,
    },
  },

  computed: {
    deferred() {
      const { data } = this
      return data.deferred
    },

    haveServers(): boolean {
      const { serversList } = this
      return serversList.length > 0
    },

    serversList() {
      return AppCtrl.serversList
    },
  },

  methods: {
    _handleAddNewServer() {
      void AppCtrl.showAddEditServerDialog()
    },

    _handleEditServer(server: Server) {
      void AppCtrl.showAddEditServerDialog(server)
    },

    _handleRemoveServer(server: Server) {
      AppCtrl.removeServer(server)
    },

    _handleConfirmDialog() {
      const { deferred } = this
      deferred.resolve()
    },
  },
})
