
// Libs
import Vue from 'vue'

// Components
import FeathersBrowser from '@/components/FeathersBrowser.vue'

// Controllers & Services
import DialogController from '@/components/DialogController.vue'

export default Vue.extend({
  name: 'FeathersView',

  components: {
    FeathersBrowser,
    DialogController,
  },
})
