
// Libs
import Vue from 'vue'
import { Deferred } from 'ts-deferred'

// Constants & Interfaces
import { ShowDialogProps } from '@/controllers/app-ctrl.interfaces'
import { AddServiceDialogProps } from '@/controllers/dialog.interfaces'
import { Events } from '@/controllers/dialog.constants'

/*
  Vuejs Interfaces
*/
export interface IProps {
  data: ShowDialogProps
}

export interface IData {
  addServiceForm: { path: string }
}
export interface IComputed {
  props: AddServiceDialogProps
  deferred: Deferred<string>
  isPathValid: boolean
  path: string
}

export interface IMethods {
  _handleCancelDialog(): void
  _handleConfirmDialog(): void
}

export default Vue.extend<IData, IMethods, IComputed, IProps>({
  props: {
    data: {
      type: Object as () => ShowDialogProps,
      required: true,
    },
  },

  data() {
    return {
      addServiceForm: {
        path: '',
      },
    }
  },

  computed: {
    props() {
      const { data } = this
      return data.props as AddServiceDialogProps
    },

    deferred() {
      const { data } = this
      return data.deferred
    },

    isPathValid(): boolean {
      const { props, addServiceForm } = this
      const { existing } = props

      const idx = existing.findIndex((path) => path === addServiceForm.path)

      return idx === -1
    },

    path(): string {
      const { addServiceForm } = this
      return addServiceForm.path
    },
  },

  methods: {
    _handleCancelDialog() {
      this.deferred.reject(Events.CANCELLED)
    },

    _handleConfirmDialog() {
      const { deferred, addServiceForm } = this
      deferred.resolve(addServiceForm.path)
    },
  },
})
